import React, { useEffect, useState } from "react";
import './Consumer.css';
import { CDBSelect, CDBInput, CDBCard, CDBCardBody, CDBDataTable } from "cdbreact";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import axios from 'axios';
import { useModal } from '../contexts/ModalContext';
import { useAuth } from '../contexts/AuthContext';
import Loading from "../components/Loading";

const API_URL = process.env.REACT_APP_API_URL;

export const Consumer = () => {
  const { username } = useAuth();
  const { openModal } = useModal();
  const [isLoading, setIsLoading] = useState(true);

  const [consumerData, setConsumerData] = useState({
    columns: [
      { label: '시군명', field: 'region', width: 100 },
      { label: '사업명', field: 'businessName', width: 100 },
      { label: '이름', field: 'name', width: 100 },
      { label: '아이디', field: 'userId', width: 150 },
      { label: '연락처', field: 'phoneNumber', width: 150 },
      { label: '공급업체', field: 'supplier', width: 250 },
      { label: '구분', field: 'businessType', width: 150 },
      { label: '사업비', field: 'baseAmount', width: 150 },
      { label: '총 사용액', field: 'totalUsedAmount', width: 150 },
      { label: '총 사용액(자부담 허용)', field: 'totalSelfPaymentAmount', width: 200 },
      { label: '총 주문 건수', field: 'totalOrderCount', width: 150 }
    ],
    rows: []
  });

  const [overallTotal, setOverallTotal] = useState({
    totalConsumerCount: 0,
    totalBusinessAmountSum: 0,
    totalUsedAmountSum: 0,
    totalSelfPaymentAmountSum: 0,
    totalOrderCountSum: 0
  });

  useEffect(() => {
    const token = localStorage.getItem('token');
    axios.get(`${API_URL}/api/consumers/gen`, {
      headers: {
        Authorization: `Bearer ${token}`  // 토큰을 헤더에 추가
      }
    })
      .then(response => {
        if (response.data.OverallTotal) {
          const overall = response.data.OverallTotal; // 첫 번째 객체 사용
          setOverallTotal({
            totalConsumerCount: overall.totalConsumerCount?.toLocaleString() || 0,
            totalBusinessAmountSum: overall.totalBusinessAmountSum?.toLocaleString() || 0,
            totalUsedAmountSum: overall.totalUsedAmountSum?.toLocaleString() || 0,
            totalSelfPaymentAmountSum: overall.totalSelfPaymentAmountSum?.toLocaleString() || 0,
            totalOrderCountSum: overall.totalOrderCountSum?.toLocaleString() || 0
          });
        }

        setConsumerData(prevData => ({
          ...prevData,
          rows: response.data.consumers.map(consumer => ({
            region: consumer.region,
            businessName: consumer.businessName,
            name: consumer.name,
            userId: consumer.userId,
            phoneNumber: consumer.phoneNumber,
            supplier: consumer.supplier,
            businessType: consumer.businessType,
            baseAmount: consumer.baseAmount?.toLocaleString(),
            totalUsedAmount: consumer.totalUsedAmount?.toLocaleString(),
            totalSelfPaymentAmount: consumer.totalSelfPaymentAmount?.toLocaleString(),
            totalOrderCount: consumer.totalOrderCount?.toLocaleString()
          }))
        }));
      })
      .catch(error => console.error('Error fetching consumer data:', error))
      .finally(() => setIsLoading(false));
  }, []);

  // 검색 필드
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedColumn, setSelectedColumn] = useState('');
  const [filteredRows, setFilteredRows] = useState([]);

  const columnOptions = consumerData.columns.map(column => ({
    text: column.label,
    value: column.field
  }));

  return (
    <div className="d-flex">
      <div>
        <Sidebar />
      </div>
      <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
        <Navbar onPasswordChangeClick={() => openModal(username)} />
        <div style={{ height: "100%" }}>
          <div style={{ padding: "20px 5%", height: "calc(100% - 64px)", overflowY: "scroll" }}>
            {isLoading ? (
              <Loading message="데이터 로딩 중.." />
            ) : (
              <div style={{ display: "grid", gridTemplateColumns: "repeat(1, minmax(200px, 1500px))" }}>
                <div className="mt-5 w-100">
                  <h3 className="font-weight-bold mb-4" style={{ fontWeight: "bold" }}>2024년 친환경농산물 꾸러미 지원사업 대상자 남도장터 아이디 발급 목록</h3>
                  <div className="search-container">
                    <div className="search-input">
                      <CDBSelect
                        className="search-select"
                        options={columnOptions}
                        selected="검색목록"
                        onChange={e => setSelectedColumn(e.target.value)}
                      />
                      <CDBInput
                        className="search-field"
                        placeholder="검색어 입력"
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                      />
                    </div>
                  </div>
                  <CDBCard>
                    <CDBCardBody>
                      <div className="summary-table-container">
                        <table className="summary-table" style={{ width: "100%", marginBottom: "20px", borderCollapse: "collapse", textAlign: "center" }}>
                          <tbody>
                            <tr style={{ border: "0.5px solid lightgray" }}>
                              <td style={{ backgroundColor: "#111827", color: "#fff", border: "1px solid lightgray", padding: "10px", fontWeight: "bold" }}>인원 총계</td>
                              <td style={{ border: "1px solid lightgray", padding: "10px", textAlign: "right" }}>{overallTotal.totalConsumerCount}</td>
                              <td style={{ backgroundColor: "#111827", color: "#fff", border: "1px solid lightgray", padding: "10px", fontWeight: "bold" }}>사업비</td>
                              <td style={{ border: "1px solid lightgray", padding: "10px", textAlign: "right" }}>{overallTotal.totalBusinessAmountSum}</td>
                              <td style={{ backgroundColor: "#111827", color: "#fff", border: "1px solid lightgray", padding: "10px", fontWeight: "bold" }}>총 사용액</td>
                              <td style={{ border: "1px solid lightgray", padding: "10px", textAlign: "right" }}>{overallTotal.totalUsedAmountSum}</td>
                              <td style={{ backgroundColor: "#111827", color: "#fff", border: "1px solid lightgray", padding: "10px", fontWeight: "bold" }}>총 사용액(자부담포함)</td>
                              <td style={{ border: "1px solid lightgray", padding: "10px", textAlign: "right" }}>{overallTotal.totalSelfPaymentAmountSum}</td>
                              <td style={{ backgroundColor: "#111827", color: "#fff", border: "1px solid lightgray", padding: "10px", fontWeight: "bold" }}>총 주문건수</td>
                              <td style={{ border: "1px solid lightgray", padding: "10px", textAlign: "right" }}>{overallTotal.totalOrderCountSum}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <CDBDataTable
                        className="table-header-center table-data-right"
                        bordered
                        hover
                        scrollY
                        maxHeight="48vh"
                        data={consumerData}
                        sortable={false}
                        searching={false}
                        infoLabel={["데이터", "~", "전체 데이터", "개"]}
                        theadColor="dark"
                        paginationLabel={["이전", "다음"]}
                      />
                    </CDBCardBody>
                  </CDBCard>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
