import React, { useState } from "react";
import { CDBCard, CDBCardBody, CDBInput, CDBBtn } from "cdbreact";
import axios from "axios";
import { useNavigate } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL;

const PasswordChangeModal = ({ username, onClose }) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordValid, setNewPasswordValid] = useState(null);
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(null);
  const navigate = useNavigate();

  const handleCurrentPasswordChange = (e) => {
    setCurrentPassword(e.target.value);
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setNewPassword(newPassword);
    checkPasswordMatch(newPassword, confirmNewPassword);
    validatePassword(newPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmNewPassword(e.target.value);
    checkPasswordMatch(newPassword, e.target.value);
  };

  const checkPasswordMatch = (newPassword, confirmNewPassword) => {
    setPasswordMatch(newPassword && confirmNewPassword && newPassword === confirmNewPassword);
  };

  const validatePassword = (password) => {
    const hasEnglishLetters = /[a-zA-Z]{2,}/.test(password);
    const hasNumbers = /\d{4,}/.test(password);
    const hasSpecialChars = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    setNewPasswordValid(hasEnglishLetters && hasNumbers && hasSpecialChars);
  };

  const handleConfirmClick = () => {
    if (!newPasswordValid) {
      alert("새로운 비밀번호를 조건에 부합하게 입력해주세요.");
      return;
    }

    if (passwordMatch && newPassword) {
      const token = localStorage.getItem('token');
      axios.put(`${API_URL}/api/users/change-password`, {
        currentPassword,
        newPassword
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(() => {
          alert(`${username}님의 비밀번호가 성공적으로 변경되었습니다.\n다시 로그인해주세요.`);
          onClose();
          navigate('/');
        })
        .catch((error) => {
          console.error('Error changing password:', error);
          alert('현재 비밀번호가 틀립니다. 다시 시도해주세요.');
        });
    } else {
      alert('새로운 비밀번호가 일치하지 않거나 입력이 누락되었습니다.');
      onClose();
    }
  };

  return (
    <div style={{
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1000
    }}>
      <CDBCard style={{ width: "25rem", padding: "25px", backgroundColor: "white", borderRadius: "15px" }}>
        <CDBCardBody>
          <h4 style={{ marginBottom: "20px", textAlign: "center" }}>비밀번호 변경</h4>
          <CDBInput label="아이디" type="text" value={username} disabled style={{ marginBottom: '20px' }} />
          <CDBInput label="현재 비밀번호" type="password" onChange={handleCurrentPasswordChange} placeholder="현재 비밀번호를 입력하세요" style={{ marginBottom: '20px' }} />
          <CDBInput label="새로운 비밀번호" type="password" onChange={handlePasswordChange} placeholder="변경할 비밀번호를 입력하세요" style={{ marginBottom: '20px' }} />
          {newPasswordValid === false && (<p style={{ color: "red", marginBottom: "20px" }}> 새로운 비밀번호는 영문자 2개 이상, 숫자 4개 이상, 특수문자 1개 이상이어야 합니다. </p>)}
          {newPasswordValid === true && (<p style={{ color: "blue", marginBottom: "20px" }}> 사용 가능한 비밀번호 입니다. </p>)}
          <CDBInput label="새로운 비밀번호 확인" type="password" onChange={handleConfirmPasswordChange} placeholder="새로운 비밀번호를 다시 입력하세요" style={{ marginBottom: '20px' }} />
          {passwordMatch === false && <p style={{ color: "red", marginBottom: "20px" }}>비밀번호가 일치하지 않습니다.</p>}
          {passwordMatch === true && <p style={{ color: "blue", marginBottom: "20px" }}>비밀번호가 일치합니다.</p>}
          <div className="d-flex justify-content-center">
            <CDBBtn color="light" onClick={onClose} style={{ marginRight: "10px" }}>취소</CDBBtn>
            <CDBBtn color="primary" onClick={handleConfirmClick}>확인</CDBBtn>
          </div>
        </CDBCardBody>
      </CDBCard>
    </div>
  );
};

export default PasswordChangeModal;
