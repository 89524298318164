import React, { createContext, useContext, useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decoded = jwtDecode(token);
        setUsername(decoded.sub || "");
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }
  }, [localStorage.getItem('token')]);

  const handleLogout = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/logout`, {}, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setUsername(""); // Context 상태 초기화
      setRole(""); // role 상태 초기화
      localStorage.removeItem('token');
      localStorage.removeItem('role');
      navigate('/');
      alert('로그아웃 됩니다.');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ username, handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
};
