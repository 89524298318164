import React, { Fragment } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Signin } from './pages/Signin';
import { Order } from './pages/Order';
import { Consumer } from './pages/Consumer';
import { ConsumerCity } from './pages/ConsumerCity';
import { Total } from './pages/Total';
import { TotalCity } from './pages/TotalCity';
import { TotalEnterprise } from './pages/TotalEnterprise';
import { UserAdmin } from './pages/UserAdmin';
import { ModalProvider } from './contexts/ModalContext';
import { AuthProvider } from './contexts/AuthContext';

const AppRoutes = () => {

  return (
    <Fragment>
      <BrowserRouter>
        <AuthProvider>
          <ModalProvider>
            <Routes>
              <Route path="/" element={<Signin />} />
              <Route path="/order" element={<Order />} />
              <Route path="/consumer" element={<Consumer />} />
              <Route path="/consumercity" element={<ConsumerCity />} />
              <Route path='/total' element={<Total />} />
              <Route path='/totalcity' element={<TotalCity />} />
              <Route path='/totalenterprise' element={<TotalEnterprise />} />
              <Route path='/useradmin' element={<UserAdmin />} />
            </Routes>
          </ModalProvider>
        </AuthProvider>
      </BrowserRouter>
    </Fragment>
  );
};

export default AppRoutes;