import React, { createContext, useContext, useState } from 'react';
import PasswordChangeModal from '../components/PasswordChangeModal';

const ModalContext = createContext();

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
  const [showModal, setShowModal] = useState(false);
  const [username, setUsername] = useState("");

  const openModal = (username) => {
    setUsername(username);
    setShowModal(true);
  };

  const closeModal = () => setShowModal(false);

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      {showModal && <PasswordChangeModal username={username} onClose={closeModal} />}
    </ModalContext.Provider>
  );
};