import styled from "styled-components";

export const Header = styled.header`
  .btn-container {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 1rem; /* 버튼 사이의 간격 설정 */
    flex-direction: row;
    flex-wrap: nowrap;
    padding-right: 1rem;
  }

	.logout-button {
    margin-left: auto;
    margin-right: 2rem; /* 기본 위치 */
  }

	@media (max-width: 992px) {
    .logout-button {
      margin-right: 1rem; /* 중간 크기 화면에서 버튼 위치 조정 */
    }
  }

  @media (max-width: 900px) {
    .btn-container {
      display: none; /* 작은 화면에서는 버튼 숨김 */
    }
  }

`;