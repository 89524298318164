import React, { useEffect, useState } from "react";
import axios from 'axios';
import { CDBTable, CDBTableHeader, CDBTableBody } from "cdbreact";
import { CDBCard, CDBCardBody, CDBInput, CDBBtn } from "cdbreact";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import { useModal } from '../contexts/ModalContext';
import { useAuth } from '../contexts/AuthContext';

const API_URL = process.env.REACT_APP_API_URL;

export const UserAdmin = () => {
  const { username: authUsername, handleLogout } = useAuth();
  const { openModal } = useModal();
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [username, setUsername] = useState("");
  const [newPassword, setnewPassword] = useState("");
  const [newPasswordValid, setNewPasswordValid] = useState(null);
  const [confirmNewPassword, setNewConfirmPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    axios.get(`${API_URL}/api/admin/list`, {
      headers: {
        Authorization: `Bearer ${token}`  // 토큰을 헤더에 추가
      }
    })
      .then(response => {
        setData(response.data);
      })
      .catch(error => console.error('Error fetching consumer data:', error));
  }, []);

  const handleOpenModal = (username) => {
    setUsername(username);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setUsername("");
    setnewPassword("");
    setNewConfirmPassword("");
    setPasswordMatch(null);
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setnewPassword(newPassword);
    validatePassword(newPassword);
    checkPasswordMatch(newPassword, confirmNewPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    setNewConfirmPassword(e.target.value);
    checkPasswordMatch(newPassword, e.target.value);
  };

  const checkPasswordMatch = (newPassword, confirmNewPassword) => {
    if (confirmNewPassword) {
      setPasswordMatch(newPassword === confirmNewPassword);
    } else {
      setPasswordMatch(null);
    }
  };

  const validatePassword = (password) => {
    const hasEnglishLetters = /[a-zA-Z]{2,}/.test(password);
    const hasNumbers = /\d{4,}/.test(password);
    const hasSpecialChars = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    setNewPasswordValid(hasEnglishLetters && hasNumbers && hasSpecialChars);
  };

  const handleConfirmClick = () => {
    if (!newPasswordValid) {
      alert("새로운 비밀번호를 조건에 부합하게 입력해주세요.");
      return;
    }

    if (passwordMatch && newPassword) {
      const token = localStorage.getItem('token');
      axios.put(`${API_URL}/api/admin/change-password`, {
        username,
        newPassword
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(() => {
          alert(`${username} 비밀번호가 변경되었습니다.`);
          handleCloseModal();
        })
        .catch((error) => {
          console.error('Error changing password:', error);
          alert('비밀번호 변경에 실패했습니다. 다시 시도해주세요.');
        });
    } else {
      alert('비밀번호가 일치하지 않거나 입력이 누락되었습니다.');
      handleCloseModal();
    }
  };

  return (
    <div className="d-flex">
      <div>
        <Sidebar />
      </div>
      <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
        <Navbar onPasswordChangeClick={() => openModal(authUsername)} />
        <div style={{ height: "100%" }}>
          <div style={{ padding: "20px 5%", height: "calc(100% - 64px)", overflowY: "scroll" }}>
            <div style={{ display: "grid", gridTemplateColumns: "repeat(1, minmax(200px, 700px))" }}>
              <div className="mt-5">
                <h3 className="font-weight-bold mb-4" style={{ fontWeight: "bold" }}>사용자 계정 관리</h3>
                <div className="mt-5" style={{ textAlign: "center" }}>
                  <CDBTable bordered responsive>
                    <CDBTableHeader>
                      <tr>
                        <th style={{ backgroundColor: "#111827", color: "white", border: "1px solid white", textAlign: "center" }}></th>
                        <th style={{ backgroundColor: "#111827", color: "white", border: "1px solid white", textAlign: "center", width: '310px' }}>사용자 이름</th>
                        <th style={{ backgroundColor: "#111827", color: "white", border: "1px solid white", textAlign: "center" }}>아이디</th>
                        <th style={{ backgroundColor: "#111827", color: "white", border: "1px solid white", textAlign: "center" }}>비밀번호</th>
                      </tr>
                    </CDBTableHeader>
                    <CDBTableBody>
                      {data.map((user, index) => (
                        <tr key={index}>
                          <td style={{ textAlign: 'center' }}>{index + 1}</td>
                          <td style={{ textAlign: 'center' }}>{user.displayName || "데이터 없음"}</td> {/* displayName이 null일 경우 "N/A" 표시 */}
                          <td style={{ textAlign: 'center' }}>{user.username}</td>
                          <td style={{ textAlign: 'center' }}>
                            <button className="btn btn-primary btn-sm" onClick={() => handleOpenModal(user.username)} >
                              변경
                            </button>
                          </td>
                        </tr>
                      ))}
                    </CDBTableBody>
                  </CDBTable>
                </div>
                {showModal && (
                  <div style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 1000
                  }}>
                    <CDBCard style={{ width: "25rem", padding: "25px", backgroundColor: "white", borderRadius: "15px" }}>
                      <CDBCardBody>
                        <h4 style={{ marginBottom: "20px", textAlign: "center" }}>비밀번호 변경</h4>
                        <CDBInput label="아이디" type="text" value={username} disabled style={{ marginBottom: '20px' }} />
                        <CDBInput label="새로운 비밀번호" type="password" onChange={handlePasswordChange} placeholder='변경할 비밀번호를 입력하세요' style={{ marginBottom: '20px' }} />
                        {newPasswordValid === false && (<p style={{ color: "red", marginBottom: "20px" }}> 새로운 비밀번호는 영문자 2개 이상, 숫자 4개 이상, 특수문자 1개 이상이어야 합니다. </p>)}
                        {newPasswordValid === true && (<p style={{ color: "blue", marginBottom: "20px" }}> 사용 가능한 비밀번호 입니다. </p>)}
                        <CDBInput label="새로운 비밀번호 확인" type="password" placeholder='위 새로운 비밀번호를 다시 입력하세요' onChange={handleConfirmPasswordChange} style={{ marginBottom: '20px' }} />
                        {passwordMatch === false && (<p style={{ color: "red", marginBottom: "20px" }}>비밀번호가 일치하지 않습니다.</p>)}
                        {passwordMatch === true && (<p style={{ color: "blue", marginBottom: "20px" }}>비밀번호가 일치합니다.</p>)}
                        <div className="d-flex justify-content-center">
                          <CDBBtn color="light" onClick={handleCloseModal} style={{ marginRight: "10px" }}>취소</CDBBtn>
                          <CDBBtn color="light" onClick={handleConfirmClick}>확인</CDBBtn>
                        </div>
                      </CDBCardBody>
                    </CDBCard>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
