import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';

const Loading = ({ message = '데이터 로딩 중..', type = 'spin', color = '#000', height = 50, width = 50 }) => {
  const [showLoading, setShowLoading] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => setShowLoading(true), 400);
    return () => clearTimeout(timer);
  }, []);

  if (!showLoading) return null;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <ReactLoading type={type} color={color} height={height} width={width} />
      <span style={{ marginTop: '10px', fontSize: '16px', color }}>{message}</span>
    </div>
  );
};

export default Loading;