import React, { useState } from "react";
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { CDBInput, CDBCard, CDBCardBody, CDBBtn, CDBContainer } from 'cdbreact';
import { useNavigate } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL;

const loginUser = async (username, password) => {
  try {
    const response = await axios.post(`${API_URL}/api/auth/login`, {
      username: username,
      password: password
    });
    const token = response.data.replace("Bearer ", "");  // "Bearer " 제거, token만 반환
    return token;  // token 반환
  } catch (error) {
    console.error("로그인 요청 실패:", error);
    throw new Error("로그인 실패");
  }
};

const saveToken = (token) => {
  localStorage.setItem('token', token);
};

const getRoleFromToken = (token) => {
  const decodedToken = jwtDecode(token);
  const role = decodedToken.role;
  localStorage.setItem('role', role);
};

export const Signin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSignIn = async () => {
    try {
      const token = await loginUser(username, password);
      if (token) {
        saveToken(token);
        getRoleFromToken(token);
        navigate('/order');
      } else {
        console.error("토큰이 없습니다.");
      }
    } catch (error) {
      alert("아이디 또는 비밀번호를 잘못 입력하셨습니다.");
      console.error("로그인 과정에서 오류 발생:", error);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSignIn();
    }
  };

  return (
    <div>
      <CDBContainer style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CDBCard
          style={{
            width: '30rem',
            background:
              'url(https://color-hex.org/colors/333333.png)',
            backgroundSize: 'cover',
          }}
        >
          <CDBCardBody className="mx-4" style={{ zIndex: '2' }}>
            <div className="text-center mt-5 mb-2">
              <p className="h4 font-weight-bold text-white"> 로그인 </p>
            </div>
            <CDBInput label={<span style={{ color: 'white' }}>Id</span>} type="text" onChange={(e) => setUsername(e.target.value)} placeholder='아이디를 입력하세요' style={{ marginBottom: '30px' }} />
            <CDBInput label={<span style={{ color: 'white' }}>Password</span>} type="password" onChange={(e) => setPassword(e.target.value)} placeholder='비밀번호를 입력하세요' style={{ marginBottom: '20px' }} onKeyDown={handleKeyDown} />

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CDBBtn color="primary" className="btn-block mx-0 my-4" onClick={handleSignIn}> 로그인</CDBBtn>
            </div>

            <div className="d-flex flex-wrap justify-content-center align-items-center">
              <CDBInput type="Checkbox" style={{ marginRight: "8px" }} />
              <p className="m-0" style={{ color: 'white' }}>Remember me</p>
            </div>

            <p className="text-white text-center mb-5" style={{ marginTop: '50px' }}>
              <img src={`${process.env.PUBLIC_URL}/namdo.png`} alt="Namdo Logo" style={{ width: "20px", height: "20px", marginRight: "8px" }} />
              <span style={{ fontSize: "14px", color: "#fff" }}>남도장터</span>
            </p>
          </CDBCardBody>
        </CDBCard>
      </CDBContainer>
    </div>
  );
};
