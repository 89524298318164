import React, { useState, useRef } from "react";
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem
} from "cdbreact";
import { NavLink } from "react-router-dom";
import { throttle } from "lodash";

const Sidebar = () => {
  const role = localStorage.getItem('role') || '';
  const [isCollapsed, setIsCollapsed] = useState(false);

  const isToggling = useRef(false);

  const toggleCollapse = throttle(() => {
    if (isToggling.current) return;
    isToggling.current = true;
    console.log("2초 간격으로 출력");
    setIsCollapsed(prev => !prev); // 상태 변경
  }, 2000);

  React.useEffect(() => {
    isToggling.current = false; // 상태 변경 완료 후, 다시 상태를 초기화
  }, [isCollapsed]);

  const roleBasedMenu = {
    ADMIN: [
      { path: "/order", icon: "list", label: "주문내역" },
      { path: "/consumer", icon: "user", label: "지원사업 대상자" },
      { path: "/total", icon: "table", label: "총괄내역" },
      { path: "/useradmin", icon: "lock", label: "계정 관리" }
    ],
    DO: [
      { path: "/order", icon: "list", label: "주문내역" },
      { path: "/consumer", icon: "user", label: "지원사업 대상자" },
      { path: "/total", icon: "table", label: "총괄내역" },
    ],
    SUPPLIER: [
      { path: "/order", icon: "list", label: "주문내역" },
      { path: "/totalenterprise", icon: "table", label: "입점업체 총괄내역" }
    ],
    REGION: [
      { path: "/order", icon: "list", label: "주문내역" },
      { path: "/consumercity", icon: "user", label: "지원사업 대상자" },
      { path: "/totalcity", icon: "table", label: "총괄내역" }
    ]
  };

  const menuItems = roleBasedMenu[role] || [];

  return (
    <div className={`app`} style={{ display: "flex", height: "100%", overflow: "scroll initial" }}>
      <CDBSidebar textColor="#fff" backgroundColor="#333" toggled={isCollapsed} onToggle={() => { }}>
        <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large" onClick={toggleCollapse}></i>}>
          <a className="text-decoration-none" style={{ color: "inherit" }}> 친환경농산물 꾸러미 </a>
        </CDBSidebarHeader>

        <CDBSidebarContent className="sidebar-content">
          <CDBSidebarMenu>
            {menuItems.length > 0 ? (
              menuItems.map((item, index) => (
                <NavLink
                  key={index}
                  to={item.path}
                  className={({ isActive }) => (isActive ? "activeClicked" : "")}
                >
                  <CDBSidebarMenuItem icon={item.icon}>{item.label}</CDBSidebarMenuItem>
                </NavLink>
              ))
            ) : (
              <p>role이 null이거나 아직 설정되지 않았습니다.</p>
            )}
          </CDBSidebarMenu>
        </CDBSidebarContent>

        <CDBSidebarFooter style={{ textAlign: "center" }}>
          <div className="sidebar-btn-wrapper" style={{ padding: "20px 5px" }}>
            <img src={`${process.env.PUBLIC_URL}/namdo.png`} alt="Namdo Logo" style={{ width: "20px", height: "20px", marginRight: "8px" }} />
            {!isCollapsed && <span style={{ fontSize: "14px", color: "#fff" }}>남도장터</span>}
          </div>
        </CDBSidebarFooter>
      </CDBSidebar>
    </div>
  );
}

export default Sidebar;