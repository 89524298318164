import React, { useEffect, useState, useRef } from "react";
import axios from 'axios';
import { CDBTable, CDBTableHeader, CDBTableBody } from "cdbreact";
import './Total.css';
import Loading from "../components/Loading";

const TotalTable = ({ apiAddress }) => {
  const [overallTotal, setOverallTotal] = useState({});
  const [groups, setGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const firstTableRef = useRef(null);
  const [tableWidth, setTableWidth] = useState("auto");

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(apiAddress, {
          headers: { Authorization: `Bearer ${token}` }
        });
        const result = response.data;
        setOverallTotal(result.OverallTotal[0] || {});
        setGroups([
          { name: "호남권친환경농산물종합물류센터", data: result.Group1, total: result.Group1Total[0] },
          { name: "담양농협", data: result.Group2, total: result.Group2Total[0] },
          { name: "자연과농부들", data: result.Group3, total: result.Group3Total[0] },
        ]);
      } catch (error) {
        console.error("데이터 가져오기 에러:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [apiAddress]);

  useEffect(() => {
    const updateTableWidth = () => {
      if (firstTableRef.current) {
        setTableWidth(firstTableRef.current.offsetWidth);
      }
    };

    updateTableWidth();
    window.addEventListener("resize", updateTableWidth);
    return () => window.removeEventListener("resize", updateTableWidth);
  }, []);

  const renderGroupRows = (group) => (
    <>
      {group.data.map((item, idx) => (
        <tr key={`${group.name}-${idx}`}>
          {idx === 0 && (
            <td rowSpan={group.data.length} style={{ textAlign: "center" }}>
              {group.name}
            </td>
          )}
          <td style={{ textAlign: "center" }}>{item.region}</td>
          <td>{item.consumerCount?.toLocaleString()}</td>
          <td>{item.budgetAmount?.toLocaleString()}</td>
          <td>{item.totalConsumers?.toLocaleString()}</td>
          <td>{item.totalOrderConsumers?.toLocaleString()}</td>
          <td>{item.totalOrders?.toLocaleString()}</td>
          <td>{item.totalExecutionAmount?.toLocaleString()}</td>
          <td>{item.totalExecutionAmountIncludingSelfPayment?.toLocaleString()}</td>
          <td>{item.executionRate?.toLocaleString()}%</td>
          <td>{item.completedConsumers?.toLocaleString()}</td>
        </tr>
      ))}
      <tr>
        <td style={{ textAlign: "center" }}>소계</td>
        <td style={{ textAlign: "center" }}>{group.data.length}개 시군</td>
        <td>{group.total?.consumerCount?.toLocaleString()}</td>
        <td>{group.total?.budgetAmount?.toLocaleString()}</td>
        <td>{group.total?.totalConsumers?.toLocaleString()}</td>
        <td>{group.total?.totalOrderConsumers?.toLocaleString()}</td>
        <td>{group.total?.totalOrders?.toLocaleString()}</td>
        <td>{group.total?.totalExecutionAmount?.toLocaleString()}</td>
        <td>{group.total?.totalExecutionAmountIncludingSelfPayment?.toLocaleString()}</td>
        <td>{group.total?.executionRate?.toLocaleString()}%</td>
        <td>{group.total?.completedConsumers?.toLocaleString()}</td>
      </tr>
    </>
  );

  if (isLoading) {
    return (
      <div style={{ marginTop: "200px" }}>
        <Loading message="데이터 로딩 중.." />
      </div>
    );
  }

  return (
    <div className='table-container'>
      <CDBTable hover bordered responsive ref={firstTableRef} style={{ width: tableWidth }}>
        <caption>업데이트 날짜: </caption>
        <CDBTableHeader style={{ backgroundColor: "#343a40", color: "white" }}>
          <tr>
            <th rowSpan="2" style={{ width: "130px", backgroundColor: "#111827", color: "white", border: "1px solid white", textAlign: "center" }}>공급업체</th>
            <th rowSpan="2" style={{ backgroundColor: "#111827", color: "white", border: "1px solid white", textAlign: "center" }}>시군</th>
            <th colSpan="2" style={{ backgroundColor: "#111827", color: "white", border: "1px solid white", textAlign: "center" }}>사업계획</th>
            <th colSpan="6" style={{ backgroundColor: "#111827", color: "white", border: "1px solid white", textAlign: "center" }}>공급실적</th>
            <th rowSpan="2" style={{ width: "150px", backgroundColor: "#111827", color: "white", border: "1px solid white", textAlign: "center" }}>사업완료 인원</th>
          </tr>
          <tr>
            <th style={{ width: "130px", backgroundColor: "#111827", color: "white", border: "1px solid white", textAlign: "center" }}>인원</th>
            <th style={{ width: "150px", backgroundColor: "#111827", color: "white", border: "1px solid white", textAlign: "center" }}>예산액</th>
            <th style={{ width: "130px", backgroundColor: "#111827", color: "white", border: "1px solid white", textAlign: "center" }}>인원</th>
            <th style={{ width: "150px", backgroundColor: "#111827", color: "white", border: "1px solid white", textAlign: "center" }}>주문인원</th>
            <th style={{ width: "150px", backgroundColor: "#111827", color: "white", border: "1px solid white", textAlign: "center" }}>주문건수</th>
            <th style={{ width: "180px", backgroundColor: "#111827", color: "white", border: "1px solid white", textAlign: "center" }}>집행액</th>
            <th style={{ width: "180px", backgroundColor: "#111827", color: "white", border: "1px solid white", textAlign: "center" }}>집행액(자부담포함)</th>
            <th style={{ width: "150px", backgroundColor: "#111827", color: "white", border: "1px solid white", textAlign: "center" }}>집행률</th>
          </tr>
        </CDBTableHeader>
        <CDBTableBody style={{ textAlign: "right", verticalAlign: "middle" }}>
          {/* Overall Total Row */}
          <tr>
            <td colSpan="2" style={{ textAlign: "center" }}>남도장터 총괄 합계</td>
            <td>{overallTotal.consumerCount?.toLocaleString()}</td>
            <td>{overallTotal.budgetAmount?.toLocaleString()}</td>
            <td>{overallTotal.totalConsumers?.toLocaleString()}</td>
            <td>{overallTotal.totalOrderConsumers?.toLocaleString()}</td>
            <td>{overallTotal.totalOrders?.toLocaleString()}</td>
            <td>{overallTotal.totalExecutionAmount?.toLocaleString()}</td>
            <td>{overallTotal.totalExecutionAmountIncludingSelfPayment?.toLocaleString()}</td>
            <td>{overallTotal.executionRate?.toLocaleString()}%</td>
            <td>{overallTotal.completedConsumers?.toLocaleString()}</td>
          </tr>

          {/* Group Rows */}
          {groups.map((group) => renderGroupRows(group))}
        </CDBTableBody>
      </CDBTable>
    </div>
  );
};

export default TotalTable;
