import React, { useState } from "react";
import { CDBBtn } from "cdbreact";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import TotalTable from './TotalTable';
import { useModal } from '../contexts/ModalContext';
import { useAuth } from '../contexts/AuthContext';

const API_URL = process.env.REACT_APP_API_URL;

export const Total = () => {
  const { username } = useAuth();
  const { openModal } = useModal();
  const [activeTab, setActiveTab] = useState("총괄");

  const apiList = {
    "총괄": `${API_URL}/api/statistics/calculate`,
    "임산부": `${API_URL}/api/statistics/business-code-a`,
    "난임부부": `${API_URL}/api/statistics/business-code-b`,
    "수도권향우": `${API_URL}/api/statistics/business-code-c`
  };

  const handleTabChange = (tabName) => { setActiveTab(tabName); };

  return (
    <div className="d-flex">
      <div>
        <Sidebar />
      </div>
      <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
        <Navbar onPasswordChangeClick={() => openModal(username)} />
        <div style={{ height: "100%" }}>
          <div style={{ padding: "20px 5%", height: "calc(100% - 64px)", overflowY: "scroll" }}>
            <div style={{ display: "grid", gridTemplateColumns: "repeat(1, minmax(200px, 1500px))" }}>
              <div className="mt-5">
                <h3 className="font-weight-bold mb-5" style={{ fontWeight: "bold" }}>2024년 {activeTab} 친환경농산물 꾸러미 공급실적</h3>

                <div className="tabs mb-4" style={{ display: "flex", justifyContent: "space-around" }}>
                  {Object.keys(apiList).map(tab => (
                    <CDBBtn
                      key={tab}
                      color={activeTab === tab ? "dark" : "light"}
                      onClick={() => handleTabChange(tab)}
                      style={{ width: "365px" }}
                    >
                      {tab}
                    </CDBBtn>
                  ))}
                </div>

                <TotalTable apiAddress={apiList[activeTab]} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};
