import React, { useEffect, useState, useRef } from "react";
import axios from 'axios';
import { CDBTable, CDBTableHeader, CDBTableBody } from "cdbreact";
import './Total.css';
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import { useModal } from '../contexts/ModalContext';
import { useAuth } from '../contexts/AuthContext';
import Loading from "../components/Loading";

const API_URL = process.env.REACT_APP_API_URL;

export const TotalEnterprise = () => {
  const { username } = useAuth();
  const { openModal } = useModal();
  const [isLoading, setIsLoading] = useState(true);

  const firstTableRef = useRef(null);
  const [tableWidth, setTableWidth] = useState("auto");

  const [data, setData] = useState({
    OverallTotal: [],
    BusinessCodeA: [],
    BusinessCodeATotal: [],
    BusinessCodeB: [],
    BusinessCodeBTotal: [],
    BusinessCodeC: [],
    BusinessCodeCTotal: []
  });

  useEffect(() => {
    const token = localStorage.getItem('token');
    axios.get(`${API_URL}/api/statistics/grouped`, {
      headers: {
        Authorization: `Bearer ${token}`  // 토큰을 헤더에 추가
      }
    })
      .then(response => {
        setData({
          OverallTotal: response.data.OverallTotal,
          BusinessCodeA: response.data.BusinessCodeA,
          BusinessCodeATotal: response.data.BusinessCodeATotal[0],
          BusinessCodeB: response.data.BusinessCodeB,
          BusinessCodeBTotal: response.data.BusinessCodeBTotal[0],
          BusinessCodeC: response.data.BusinessCodeC,
          BusinessCodeCTotal: response.data.BusinessCodeCTotal[0]
        });
      })
      .catch(error => console.error('Error fetching consumer data:', error))
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    const updateTableWidth = () => {
      if (firstTableRef.current) {
        setTableWidth(firstTableRef.current.offsetWidth);
      }
    };

    // 처음 로드될 때와 창 크기 조절할 때마다 너비 업데이트
    updateTableWidth();
    window.addEventListener("resize", updateTableWidth);
    return () => window.removeEventListener("resize", updateTableWidth);
  }, []);

  return (
    <div className="d-flex">
      <div>
        <Sidebar />
      </div>
      <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
        <Navbar onPasswordChangeClick={() => openModal(username)} />
        <div style={{ height: "100%" }}>
          <div style={{ padding: "20px 5%", height: "calc(100% - 64px)", overflowY: "scroll" }}>
            {isLoading ? (
              <Loading message="데이터 로딩 중.." />
            ) : (
              <div style={{ display: "grid", gridTemplateColumns: "repeat(1, minmax(200px, 1500px))" }}>
                <div className="mt-5">
                  <h3 className="font-weight-bold mb-4" style={{ fontWeight: "bold" }}>입점업체</h3>
                  <div className='table-container'>
                    <CDBTable hover bordered responsive ref={firstTableRef} style={{ width: tableWidth }}>
                      <caption>업데이트 날짜: </caption>
                      <CDBTableHeader style={{ textAlign: "center", verticalAlign: "middle" }}>
                        <tr>
                          <th rowSpan="2" style={{ width: "130px", backgroundColor: "#111827", color: "white", border: "1px solid white", textAlign: "center" }}>공급업체</th>
                          <th colSpan="2" style={{ color: "white", backgroundColor: "#111827", border: "1px solid white", textAlign: "center" }}>사업계획</th>
                          <th colSpan="6" style={{ color: "white", backgroundColor: "#111827", border: "1px solid white", textAlign: "center" }}>공급실적</th>
                          <th rowSpan="2" style={{ width: "150px", backgroundColor: "#111827", color: "white", border: "1px solid white", textAlign: "center" }}>사업완료 인원</th>
                        </tr>
                        <tr>
                          <th style={{ width: "130px", backgroundColor: "#111827", color: "white", border: "1px solid white", textAlign: "center" }}>인원</th>
                          <th style={{ width: "150px", backgroundColor: "#111827", color: "white", border: "1px solid white", textAlign: "center" }}>예산액</th>
                          <th style={{ width: "130px", backgroundColor: "#111827", color: "white", border: "1px solid white", textAlign: "center" }}>인원</th>
                          <th style={{ width: "150px", backgroundColor: "#111827", color: "white", border: "1px solid white", textAlign: "center" }}>주문인원</th>
                          <th style={{ width: "150px", backgroundColor: "#111827", color: "white", border: "1px solid white", textAlign: "center" }}>주문건수</th>
                          <th style={{ width: "180px", backgroundColor: "#111827", color: "white", border: "1px solid white", textAlign: "center" }}>집행액</th>
                          <th style={{ width: "180px", backgroundColor: "#111827", color: "white", border: "1px solid white", textAlign: "center" }}>집행액(자부담포함)</th>
                          <th style={{ width: "150px", backgroundColor: "#111827", color: "white", border: "1px solid white", textAlign: "center" }}>집행률</th>
                        </tr>
                      </CDBTableHeader>
                      <CDBTableBody>
                        {data.OverallTotal.length > 0 ? (
                          <>
                            <tr>
                              <td style={{ textAlign: "center" }}>합계</td>
                              <td >{data.OverallTotal[0]?.consumerCount?.toLocaleString() || ''}</td>
                              <td>{data.OverallTotal[0]?.budgetAmount?.toLocaleString() || ''}</td>
                              <td>{data.OverallTotal[0]?.totalConsumers?.toLocaleString() || ''}</td>
                              <td>{data.OverallTotal[0]?.totalOrderConsumers?.toLocaleString() || ''}</td>
                              <td>{data.OverallTotal[0]?.totalOrders?.toLocaleString() || ''}</td>
                              <td>{data.OverallTotal[0]?.totalExecutionAmount?.toLocaleString() || ''}</td>
                              <td>{data.OverallTotal[0]?.totalExecutionAmountIncludingSelfPayment?.toLocaleString() || ''}</td>
                              <td>{data.OverallTotal[0]?.executionRate?.toLocaleString() || ''}%</td>
                              <td>{data.OverallTotal[0]?.completedConsumers?.toLocaleString() || ''}</td>
                            </tr>
                            {/* 임산부 */}
                            <tr>
                              <td style={{ textAlign: "center" }}>임산부</td>
                              <td>{data.OverallTotal[1]?.consumerCount?.toLocaleString() || ''}</td>
                              <td>{data.OverallTotal[1]?.budgetAmount?.toLocaleString() || ''}</td>
                              <td>{data.OverallTotal[1]?.totalConsumers?.toLocaleString() || ''}</td>
                              <td>{data.OverallTotal[1]?.totalOrderConsumers?.toLocaleString() || ''}</td>
                              <td>{data.OverallTotal[1]?.totalOrders?.toLocaleString() || ''}</td>
                              <td>{data.OverallTotal[1]?.totalExecutionAmount?.toLocaleString() || ''}</td>
                              <td>{data.OverallTotal[1]?.totalExecutionAmountIncludingSelfPayment?.toLocaleString() || ''}</td>
                              <td>{data.OverallTotal[1]?.executionRate?.toLocaleString() || ''}%</td>
                              <td>{data.OverallTotal[1]?.completedConsumers?.toLocaleString() || ''}</td>
                            </tr>
                            {/* 난임부부 */}
                            <tr>
                              <td style={{ textAlign: "center" }}>난임부부</td>
                              <td>{data.OverallTotal[2]?.consumerCount?.toLocaleString() || ''}</td>
                              <td>{data.OverallTotal[2]?.budgetAmount?.toLocaleString() || ''}</td>
                              <td>{data.OverallTotal[2]?.totalConsumers?.toLocaleString() || ''}</td>
                              <td>{data.OverallTotal[2]?.totalOrderConsumers?.toLocaleString() || ''}</td>
                              <td>{data.OverallTotal[2]?.totalOrders?.toLocaleString() || ''}</td>
                              <td>{data.OverallTotal[2]?.totalExecutionAmount?.toLocaleString() || ''}</td>
                              <td>{data.OverallTotal[2]?.totalExecutionAmountIncludingSelfPayment?.toLocaleString() || ''}</td>
                              <td>{data.OverallTotal[2]?.executionRate?.toLocaleString() || ''}%</td>
                              <td>{data.OverallTotal[2]?.completedConsumers?.toLocaleString() || ''}</td>
                            </tr>
                            {/* 수도권향우 */}
                            <tr>
                              <td style={{ textAlign: "center" }}>수도권향우</td>
                              <td>{data.OverallTotal[3]?.consumerCount?.toLocaleString() || ''}</td>
                              <td>{data.OverallTotal[3]?.budgetAmount?.toLocaleString() || ''}</td>
                              <td>{data.OverallTotal[3]?.totalConsumers?.toLocaleString() || ''}</td>
                              <td>{data.OverallTotal[3]?.totalOrderConsumers?.toLocaleString() || ''}</td>
                              <td>{data.OverallTotal[3]?.totalOrders?.toLocaleString() || ''}</td>
                              <td>{data.OverallTotal[3]?.totalExecutionAmount?.toLocaleString() || ''}</td>
                              <td>{data.OverallTotal[3]?.totalExecutionAmountIncludingSelfPayment?.toLocaleString() || ''}</td>
                              <td>{data.OverallTotal[3]?.executionRate?.toLocaleString() || ''}%</td>
                              <td>{data.OverallTotal[3]?.completedConsumers?.toLocaleString() || ''}</td>
                            </tr>
                          </>
                        ) : (
                          <tr>
                            <td colSpan="10" style={{ textAlign: "center" }}>데이터가 없습니다</td>
                          </tr>
                        )}
                      </CDBTableBody>
                    </CDBTable>

                    <CDBTable hover bordered responsive style={{ width: tableWidth }}>
                      <CDBTableHeader style={{ textAlign: "left", verticalAlign: "middle", backgroundColor: "#111827", color: "white" }}>
                        <th style={{ border: "1px solid white", textAlign: "center" }}>사업명</th>
                        <th style={{ border: "1px solid white", textAlign: "center" }}>시/군</th>
                        <th style={{ border: "1px solid white", textAlign: "center" }}>인원</th>
                        <th style={{ border: "1px solid white", textAlign: "center" }}>예산액</th>
                        <th style={{ border: "1px solid white", textAlign: "center" }}>인원</th>
                        <th style={{ border: "1px solid white", textAlign: "center" }}>주문인원</th>
                        <th style={{ border: "1px solid white", textAlign: "center" }}>주문건수</th>
                        <th style={{ border: "1px solid white", textAlign: "center" }}>집행액</th>
                        <th style={{ border: "1px solid white", textAlign: "center" }}>집행액(자부담포함)</th>
                        <th style={{ border: "1px solid white", textAlign: "center" }}>집행률</th>
                      </CDBTableHeader>
                      <CDBTableBody style={{ textAlign: "right", verticalAlign: "middle" }} color="dark">
                        {data.BusinessCodeA.map((group, index) => (
                          <React.Fragment key={index}>
                            {index === 0 && (
                              <tr>
                                <td rowSpan={data.BusinessCodeA.length} style={{ textAlign: "center", width: "130px" }}>임산부</td>
                                <td style={{ textAlign: "center", width: "130px" }}>{group.region}</td>
                                <td style={{ width: "150px" }}>{group.consumerCount?.toLocaleString()}</td>
                                <td style={{ width: "130px" }}>{group.budgetAmount?.toLocaleString()}</td>
                                <td style={{ width: "150px" }}>{group.totalConsumers?.toLocaleString()}</td>
                                <td style={{ width: "150px" }}>{group.totalOrderConsumers?.toLocaleString()}</td>
                                <td style={{ width: "180px" }}>{group.totalOrders?.toLocaleString()}</td>
                                <td style={{ width: "180px" }}>{group.totalExecutionAmount?.toLocaleString()}</td>
                                <td style={{ width: "150px" }}>{group.totalExecutionAmountIncludingSelfPayment?.toLocaleString()}</td>
                                <td style={{ width: "150px" }}>{group.executionRate?.toLocaleString()}%</td>
                              </tr>
                            )}
                            {index > 0 && index < data.BusinessCodeA.length && (
                              <tr>
                                <td style={{ textAlign: "center" }}>{group.region}</td>
                                <td>{group.consumerCount?.toLocaleString()}</td>
                                <td>{group.budgetAmount?.toLocaleString()}</td>
                                <td>{group.totalConsumers?.toLocaleString()}</td>
                                <td>{group.totalOrderConsumers?.toLocaleString()}</td>
                                <td>{group.totalOrders?.toLocaleString()}</td>
                                <td>{group.totalExecutionAmount?.toLocaleString()}</td>
                                <td>{group.totalExecutionAmountIncludingSelfPayment?.toLocaleString()}</td>
                                <td>{group.executionRate?.toLocaleString()}%</td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))}
                        <tr>
                          <td style={{ textAlign: "center" }}>소계</td>
                          <td style={{ textAlign: "center" }}>{data.BusinessCodeA.length}개 시군</td>
                          <td>{data.BusinessCodeATotal?.consumerCount?.toLocaleString() || ''}</td>
                          <td>{data.BusinessCodeATotal?.budgetAmount?.toLocaleString() || ''}</td>
                          <td>{data.BusinessCodeATotal?.totalConsumers?.toLocaleString() || ''}</td>
                          <td>{data.BusinessCodeATotal?.totalOrderConsumers?.toLocaleString() || ''}</td>
                          <td>{data.BusinessCodeATotal?.totalOrders?.toLocaleString() || ''}</td>
                          <td>{data.BusinessCodeATotal?.totalExecutionAmount?.toLocaleString() || ''}</td>
                          <td>{data.BusinessCodeATotal?.totalExecutionAmountIncludingSelfPayment?.toLocaleString() || ''}</td>
                          <td>{data.BusinessCodeATotal?.executionRate?.toLocaleString() || ''}%</td>
                        </tr>
                      </CDBTableBody>
                    </CDBTable>

                    {/* 난임부부 */}
                    <CDBTable hover bordered responsive style={{ width: tableWidth }}>
                      <CDBTableHeader style={{ textAlign: "center", verticalAlign: "middle", backgroundColor: "#111827", color: "white" }} color='dark'></CDBTableHeader>
                      <CDBTableBody style={{ textAlign: "right", verticalAlign: "middle" }} color="dark">
                        {data.BusinessCodeB.map((group, index) => (
                          <React.Fragment key={index}>
                            {index === 0 && (
                              <tr>
                                <td rowSpan={data.BusinessCodeB.length} style={{ textAlign: "center", width: "130px" }}>난임부부</td>
                                <td style={{ textAlign: "center", width: "130px" }}>{group.region}</td>
                                <td style={{ width: "150px" }}>{group.consumerCount?.toLocaleString()}</td>
                                <td style={{ width: "130px" }}>{group.budgetAmount?.toLocaleString()}</td>
                                <td style={{ width: "150px" }}>{group.totalConsumers?.toLocaleString()}</td>
                                <td style={{ width: "150px" }}>{group.totalOrderConsumers?.toLocaleString()}</td>
                                <td style={{ width: "180px" }}>{group.totalOrders?.toLocaleString()}</td>
                                <td style={{ width: "180px" }}>{group.totalExecutionAmount?.toLocaleString()}</td>
                                <td style={{ width: "150px" }}>{group.totalExecutionAmountIncludingSelfPayment?.toLocaleString()}</td>
                                <td style={{ width: "150px" }}>{group.executionRate?.toLocaleString()}%</td>
                              </tr>
                            )}
                            {index > 0 && index < data.BusinessCodeB.length && (
                              <tr>
                                <td style={{ textAlign: "center" }}>{group.region}</td>
                                <td>{group.consumerCount?.toLocaleString()}</td>
                                <td>{group.budgetAmount?.toLocaleString()}</td>
                                <td>{group.totalConsumers?.toLocaleString()}</td>
                                <td>{group.totalOrderConsumers?.toLocaleString()}</td>
                                <td>{group.totalOrders?.toLocaleString()}</td>
                                <td>{group.totalExecutionAmount?.toLocaleString()}</td>
                                <td>{group.totalExecutionAmountIncludingSelfPayment?.toLocaleString()}</td>
                                <td>{group.executionRate?.toLocaleString()}%</td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))}
                        <tr>
                          <td style={{ textAlign: "center" }}>소계</td>
                          <td style={{ textAlign: "center" }}>{data.BusinessCodeB.length}개 시군</td>
                          <td>{data.BusinessCodeBTotal?.consumerCount?.toLocaleString() || ''}</td>
                          <td>{data.BusinessCodeBTotal?.budgetAmount?.toLocaleString() || ''}</td>
                          <td>{data.BusinessCodeBTotal?.totalConsumers?.toLocaleString() || ''}</td>
                          <td>{data.BusinessCodeBTotal?.totalOrderConsumers?.toLocaleString() || ''}</td>
                          <td>{data.BusinessCodeBTotal?.totalOrders?.toLocaleString() || ''}</td>
                          <td>{data.BusinessCodeBTotal?.totalExecutionAmount?.toLocaleString() || ''}</td>
                          <td>{data.BusinessCodeBTotal?.totalExecutionAmountIncludingSelfPayment?.toLocaleString() || ''}</td>
                          <td>{data.BusinessCodeBTotal?.executionRate?.toLocaleString() || ''}%</td>
                        </tr>
                      </CDBTableBody>
                    </CDBTable>

                    {/* 수도권향우 */}
                    <CDBTable hover bordered responsive style={{ width: tableWidth }}>
                      <CDBTableHeader style={{ textAlign: "center", verticalAlign: "middle", backgroundColor: "#111827", color: "white" }} color='dark'></CDBTableHeader>
                      <CDBTableBody style={{ textAlign: "right", verticalAlign: "middle" }} color="dark">
                        {data.BusinessCodeC.map((group, index) => (
                          <React.Fragment key={index}>
                            {index === 0 && (
                              <tr>
                                <td rowSpan={data.BusinessCodeC.length} style={{ textAlign: "center", width: "130px" }}>수도권향우</td>
                                <td style={{ textAlign: "center", width: "130px" }}>{group.region}</td>
                                <td style={{ width: "150px" }}>{group.consumerCount?.toLocaleString()}</td>
                                <td style={{ width: "130px" }}>{group.budgetAmount?.toLocaleString()}</td>
                                <td style={{ width: "150px" }}>{group.totalConsumers?.toLocaleString()}</td>
                                <td style={{ width: "150px" }}>{group.totalOrderConsumers?.toLocaleString()}</td>
                                <td style={{ width: "180px" }}>{group.totalOrders?.toLocaleString()}</td>
                                <td style={{ width: "180px" }}>{group.totalExecutionAmount?.toLocaleString()}</td>
                                <td style={{ width: "150px" }}>{group.totalExecutionAmountIncludingSelfPayment?.toLocaleString()}</td>
                                <td style={{ width: "150px" }}>{group.executionRate?.toLocaleString()}%</td>
                              </tr>
                            )}
                            {index > 0 && index < data.BusinessCodeC.length && (
                              <tr>
                                <td style={{ textAlign: "center" }}>{group.region}</td>
                                <td>{group.consumerCount?.toLocaleString()}</td>
                                <td>{group.budgetAmount?.toLocaleString()}</td>
                                <td>{group.totalConsumers?.toLocaleString()}</td>
                                <td>{group.totalOrderConsumers?.toLocaleString()}</td>
                                <td>{group.totalOrders?.toLocaleString()}</td>
                                <td>{group.totalExecutionAmount?.toLocaleString()}</td>
                                <td>{group.totalExecutionAmountIncludingSelfPayment?.toLocaleString()}</td>
                                <td>{group.executionRate?.toLocaleString()}%</td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))}
                        <tr>
                          <td style={{ textAlign: "center" }}>소계</td>
                          <td style={{ textAlign: "center" }}>{data.BusinessCodeC.length}개 시군</td>
                          <td>{data.BusinessCodeCTotal?.consumerCount?.toLocaleString() || ''}</td>
                          <td>{data.BusinessCodeCTotal?.budgetAmount?.toLocaleString() || ''}</td>
                          <td>{data.BusinessCodeCTotal?.totalConsumers?.toLocaleString() || ''}</td>
                          <td>{data.BusinessCodeCTotal?.totalOrderConsumers?.toLocaleString() || ''}</td>
                          <td>{data.BusinessCodeCTotal?.totalOrders?.toLocaleString() || ''}</td>
                          <td>{data.BusinessCodeCTotal?.totalExecutionAmount?.toLocaleString() || ''}</td>
                          <td>{data.BusinessCodeCTotal?.totalExecutionAmountIncludingSelfPayment?.toLocaleString() || ''}</td>
                          <td>{data.BusinessCodeCTotal?.executionRate?.toLocaleString() || ''}%</td>
                        </tr>
                      </CDBTableBody>
                    </CDBTable>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div >
  );
};