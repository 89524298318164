import React, { useEffect, useState, useRef } from "react";
import axios from 'axios';
import { CDBTable, CDBTableHeader, CDBTableBody } from "cdbreact";
import './Total.css';
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import { useModal } from '../contexts/ModalContext';
import { useAuth } from '../contexts/AuthContext';
import Loading from "../components/Loading";

const API_URL = process.env.REACT_APP_API_URL;

export const TotalCity = () => {
  const { username } = useAuth();
  const { openModal } = useModal();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({ OverallTotal: [] });

  const firstTableRef = useRef(null);
  const [tableWidth, setTableWidth] = useState("auto");

  useEffect(() => {
    const token = localStorage.getItem('token');
    axios.get(`${API_URL}/api/statistics/region`, {
      headers: {
        Authorization: `Bearer ${token}`  // 토큰을 헤더에 추가
      }
    })
      .then(response => {
        setData({
          OverallTotal: response.data.OverallTotal,
        });
      })
      .catch(error => console.error('Error fetching consumer data:', error))
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    const updateTableWidth = () => {
      if (firstTableRef.current) {
        setTableWidth(firstTableRef.current.offsetWidth);
      }
    };

    updateTableWidth();
    window.addEventListener("resize", updateTableWidth);
    return () => window.removeEventListener("resize", updateTableWidth);
  }, []);

  return (
    <div className="d-flex">
      <div>
        <Sidebar />
      </div>
      <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
        <Navbar onPasswordChangeClick={() => openModal(username)} />
        <div style={{ height: "100%" }}>
          <div style={{ padding: "20px 5%", height: "calc(100% - 64px)", overflowY: "scroll" }}>
            {isLoading ? (
              <Loading message="데이터 로딩 중.." />
            ) : (
              <div style={{ display: "grid", gridTemplateColumns: "repeat(1, minmax(200px, 1500px))" }}>
                <div className="mt-5">
                  <h3 className="font-weight-bold mb-4" style={{ fontWeight: "bold" }}>시/군별 2024년 전남 친환경농산물 꾸러미 공급실적(총괄)</h3>
                  <div className='table-container'>
                    <CDBTable hover bordered responsive ref={firstTableRef} style={{ width: tableWidth }}>
                      <caption>업데이트 날짜: </caption>
                      <CDBTableHeader style={{ textAlign: "center", verticalAlign: "middle" }} >
                        <tr>
                          <th rowSpan="2" style={{ width: "130px", backgroundColor: "#111827", color: "white", border: "1px solid white", textAlign: "center" }}>공급업체</th>
                          <th colSpan="2" style={{ color: "white", backgroundColor: "#111827", border: "1px solid white", textAlign: "center" }}>사업계획</th>
                          <th colSpan="6" style={{ color: "white", backgroundColor: "#111827", border: "1px solid white", textAlign: "center" }}>공급실적</th>
                          <th rowSpan="2" style={{ width: "150px", backgroundColor: "#111827", color: "white", border: "1px solid white", textAlign: "center" }}>사업완료 인원</th>
                        </tr>
                        <tr>
                          <th style={{ width: "130px", backgroundColor: "#111827", color: "white", border: "1px solid white", textAlign: "center" }}>인원</th>
                          <th style={{ width: "150px", backgroundColor: "#111827", color: "white", border: "1px solid white", textAlign: "center" }}>예산액</th>
                          <th style={{ width: "130px", backgroundColor: "#111827", color: "white", border: "1px solid white", textAlign: "center" }}>인원</th>
                          <th style={{ width: "150px", backgroundColor: "#111827", color: "white", border: "1px solid white", textAlign: "center" }}>주문인원</th>
                          <th style={{ width: "150px", backgroundColor: "#111827", color: "white", border: "1px solid white", textAlign: "center" }}>주문건수</th>
                          <th style={{ width: "180px", backgroundColor: "#111827", color: "white", border: "1px solid white", textAlign: "center" }}>집행액</th>
                          <th style={{ width: "180px", backgroundColor: "#111827", color: "white", border: "1px solid white", textAlign: "center" }}>집행액(자부담포함)</th>
                          <th style={{ width: "150px", backgroundColor: "#111827", color: "white", border: "1px solid white", textAlign: "center" }}>집행률</th>
                        </tr>
                      </CDBTableHeader>
                      <CDBTableBody>
                        {data.OverallTotal.length > 0 ? (
                          <>
                            <tr>
                              <td style={{ textAlign: "center" }}>합계</td>
                              <td style={{ textAlign: "right" }}>{data.OverallTotal[0]?.consumerCount?.toLocaleString() || '데이터 로딩'}</td>
                              <td style={{ textAlign: "right" }}>{data.OverallTotal[0]?.budgetAmount?.toLocaleString() || '데이터 로딩'}</td>
                              <td style={{ textAlign: "right" }}>{data.OverallTotal[0]?.totalConsumers?.toLocaleString() || '데이터 로딩'}</td>
                              <td style={{ textAlign: "right" }}>{data.OverallTotal[0]?.totalOrderConsumers?.toLocaleString() || '데이터 로딩'}</td>
                              <td style={{ textAlign: "right" }}>{data.OverallTotal[0]?.totalOrders?.toLocaleString() || '데이터 로딩'}</td>
                              <td style={{ textAlign: "right" }}>{data.OverallTotal[0]?.totalExecutionAmount?.toLocaleString() || '데이터 로딩'}</td>
                              <td style={{ textAlign: "right" }}>{data.OverallTotal[0]?.totalExecutionAmountIncludingSelfPayment?.toLocaleString() || '데이터 로딩'}</td>
                              <td style={{ textAlign: "right" }}>{data.OverallTotal[0]?.executionRate.toLocaleString() || '데이터 로딩'}%</td>
                              <td style={{ textAlign: "right" }}>{data.OverallTotal[0]?.completedConsumers?.toLocaleString() || '데이터 로딩'}</td>
                            </tr>
                            {/* 임산부 */}
                            <tr>
                              <td style={{ textAlign: "center" }}>임산부</td>
                              <td style={{ textAlign: "right" }}>{data.OverallTotal[1]?.consumerCount?.toLocaleString() || '데이터 로딩'}</td>
                              <td style={{ textAlign: "right" }}>{data.OverallTotal[1]?.budgetAmount?.toLocaleString() || '데이터 로딩'}</td>
                              <td style={{ textAlign: "right" }}>{data.OverallTotal[1]?.totalConsumers?.toLocaleString() || '데이터 로딩'}</td>
                              <td style={{ textAlign: "right" }}>{data.OverallTotal[1]?.totalOrderConsumers?.toLocaleString() || '데이터 로딩'}</td>
                              <td style={{ textAlign: "right" }}>{data.OverallTotal[1]?.totalOrders?.toLocaleString() || '데이터 로딩'}</td>
                              <td style={{ textAlign: "right" }}>{data.OverallTotal[1]?.totalExecutionAmount?.toLocaleString() || '데이터 로딩'}</td>
                              <td style={{ textAlign: "right" }}>{data.OverallTotal[1]?.totalExecutionAmountIncludingSelfPayment?.toLocaleString() || '데이터 로딩'}</td>
                              <td style={{ textAlign: "right" }}>{data.OverallTotal[1]?.executionRate.toLocaleString() || '데이터 로딩'}%</td>
                              <td style={{ textAlign: "right" }}>{data.OverallTotal[1]?.completedConsumers?.toLocaleString() || '데이터 로딩'}</td>
                            </tr>
                            {/* 난임부부 */}
                            <tr>
                              <td style={{ textAlign: "center" }}>난임부부</td>
                              <td style={{ textAlign: "right" }}>{data.OverallTotal[2]?.consumerCount?.toLocaleString() || '데이터 로딩'}</td>
                              <td style={{ textAlign: "right" }}>{data.OverallTotal[2]?.budgetAmount?.toLocaleString() || '데이터 로딩'}</td>
                              <td style={{ textAlign: "right" }}>{data.OverallTotal[2]?.totalConsumers?.toLocaleString() || '데이터 로딩'}</td>
                              <td style={{ textAlign: "right" }}>{data.OverallTotal[2]?.totalOrderConsumers?.toLocaleString() || '데이터 로딩'}</td>
                              <td style={{ textAlign: "right" }}>{data.OverallTotal[2]?.totalOrders?.toLocaleString() || '데이터 로딩'}</td>
                              <td style={{ textAlign: "right" }}>{data.OverallTotal[2]?.totalExecutionAmount?.toLocaleString() || '데이터 로딩'}</td>
                              <td style={{ textAlign: "right" }}>{data.OverallTotal[2]?.totalExecutionAmountIncludingSelfPayment?.toLocaleString() || '데이터 로딩'}</td>
                              <td style={{ textAlign: "right" }}>{data.OverallTotal[2]?.executionRate.toLocaleString() || '데이터 로딩'}%</td>
                              <td style={{ textAlign: "right" }}>{data.OverallTotal[2]?.completedConsumers?.toLocaleString() || '데이터 로딩'}</td>
                            </tr>
                            {/* 수도권향우 */}
                            <tr>
                              <td style={{ textAlign: "center" }}>수도권향우</td>
                              <td style={{ textAlign: "right" }}>{data.OverallTotal[3]?.consumerCount?.toLocaleString() || '데이터 로딩'}</td>
                              <td style={{ textAlign: "right" }}>{data.OverallTotal[3]?.budgetAmount?.toLocaleString() || '데이터 로딩'}</td>
                              <td style={{ textAlign: "right" }}>{data.OverallTotal[3]?.totalConsumers?.toLocaleString() || '데이터 로딩'}</td>
                              <td style={{ textAlign: "right" }}>{data.OverallTotal[3]?.totalOrderConsumers?.toLocaleString() || '데이터 로딩'}</td>
                              <td style={{ textAlign: "right" }}>{data.OverallTotal[3]?.totalOrders?.toLocaleString() || '데이터 로딩'}</td>
                              <td style={{ textAlign: "right" }}>{data.OverallTotal[3]?.totalExecutionAmount?.toLocaleString() || '데이터 로딩'}</td>
                              <td style={{ textAlign: "right" }}>{data.OverallTotal[3]?.totalExecutionAmountIncludingSelfPayment?.toLocaleString() || '데이터 로딩'}</td>
                              <td style={{ textAlign: "right" }}>{data.OverallTotal[3]?.executionRate.toLocaleString() || '데이터 로딩'}%</td>
                              <td style={{ textAlign: "right" }}>{data.OverallTotal[3]?.completedConsumers?.toLocaleString() || '데이터 로딩'}</td>
                            </tr>
                          </>
                        ) : (
                          <tr>
                            <td colSpan="10" style={{ textAlign: "center" }}>데이터가 없습니다</td>
                          </tr>
                        )}
                      </CDBTableBody>
                    </CDBTable>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
