import React from "react";
import { Header } from "./Navbar.style";
import { CDBBtn, CDBNavbar } from "cdbreact";
import { useAuth } from './contexts/AuthContext';

const API_URL = process.env.REACT_APP_API_URL;

const Navbar = ({ onPasswordChangeClick }) => {
  const { handleLogout } = useAuth();

  return (
    <Header style={{ background: "#333", color: "#fff" }}>
      <CDBNavbar dark expand="md" scrolling className="justify-content-start">
        <div className="btn-container">
          <CDBBtn color="light" className="btn-block mx-0 my-2" onClick={onPasswordChangeClick}> 비밀번호 변경</CDBBtn>
          <CDBBtn color="primary" className="btn-block mx-0 my-2 logout-button" onClick={handleLogout}> 로그아웃</CDBBtn>
        </div>
      </CDBNavbar>
    </Header>
  );
}

export default Navbar;
