import React, { useEffect, useState } from "react";
import './Order.css';
import { CDBSelect, CDBInput, CDBCard, CDBCardBody, CDBDataTable } from 'cdbreact';
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import axios from 'axios';
import { useModal } from '../contexts/ModalContext';
import { useAuth } from '../contexts/AuthContext';
import Loading from "../components/Loading";

const API_URL = process.env.REACT_APP_API_URL;

export const Order = () => {
  const { username } = useAuth();
  const { openModal } = useModal();
  const [isLoading, setIsLoading] = useState(true);

  const [orderData, setOrderData] = useState({
    columns: [
      { label: '순번', field: 'orderSeq', width: 80 },
      { label: '업체지역', field: 'region', width: 90 },
      { label: '공급업체', field: 'supplier', width: 400, cell: (row) => <div style={{ textAlign: 'left' }}>{row.supplier}</div> },
      { label: '주문경로', field: 'orderPath', width: 90 },
      { label: '주문번호', field: 'orderNumber', width: 200 },
      { label: '주문상세번호', field: 'orderDetailNumber', width: 120 },
      { label: '내부주문번호', field: 'internalOrderNumber', width: 300 },
      { label: '주문자', field: 'customer', width: 300 },
      { label: '주문자연락처', field: 'customerContact', width: 150 },
      { label: '수령자', field: 'receiver', width: 300 },
      { label: '수령자연락처', field: 'receiverContact', width: 150 },
      { label: '상품명', field: 'productName', width: 550 },
      { label: '옵션명', field: 'optionName', width: 300 },
      { label: '진행상태', field: 'status', width: 100 },
      { label: '수량', field: 'quantity', width: 50 },
      { label: '배송비', field: 'deliveryFee', width: 100 },
      { label: '공급가', field: 'supplyPrice', width: 100 },
      { label: '판매가', field: 'salesPrice', width: 100 },
      { label: '정산가', field: 'settlementPrice', width: 100 },
      { label: '과세구분', field: 'taxType', width: 100 },
      { label: '택배사', field: 'shippingCompany', width: 150 },
      { label: '송장번호', field: 'trackingNumber', width: 150 },
      { label: '출고일자', field: 'shipDate', width: 150 },
      { label: '사용자ID', field: 'id', width: 150 },
      { label: '사업코드', field: 'businessCode', width: 150 },
      { label: '사업명', field: 'businessName', width: 150 },
      { label: '남도장터 정산금액', field: 'settlementAmount', width: 150 },
      { label: '시군보조금', field: 'subsidy', width: 150 },
      { label: '남도장터 정산단가', field: 'settlementUnitPrice', width: 150 },
      { label: '시군보조금 단가', field: 'subsidyUnitPrice', width: 150 },
      { label: '공급단가', field: 'supplyUnitPrice', width: 150 },
      { label: '판매단가', field: 'salesUnitPrice', width: 150 },
      { label: '주문월', field: 'orderMonth', width: 150 },
      { label: '시군', field: 'regionName', width: 150 },
    ],
    rows: []
  });

  useEffect(() => {
    const token = localStorage.getItem('token');
    axios.get(`${API_URL}/api/orders/region`, {
      headers: {
        Authorization: `Bearer ${token}`  // 토큰을 헤더에 추가
      }
    })
      .then(response => {
        if (response.data && Array.isArray(response.data)) {
          setOrderData(prevData => ({
            ...prevData,
            rows: response.data.map(order => ({
              orderSeq: order.orderSeq,
              region: order.region,
              supplier: order.supplier,
              orderDate: order.orderDate,
              orderPath: order.orderPath,
              orderNumber: order.orderNumber,
              orderDetailNumber: order.orderDetailNumber,
              internalOrderNumber: order.internalOrderNumber,
              customer: order.customer,
              customerContact: order.customerContact,
              receiver: order.receiver,
              receiverContact: order.receiverContact,
              productName: order.productName,
              optionName: order.optionName,
              status: order.status,
              quantity: order.quantity?.toLocaleString(),
              deliveryFee: order.deliveryFee?.toLocaleString(),
              supplyPrice: order.supplyPrice?.toLocaleString(),
              salesPrice: order.salesPrice?.toLocaleString(),
              settlementPrice: order.settlementPrice?.toLocaleString(),
              taxType: order.taxType,
              shippingCompany: order.shippingCompany,
              trackingNumber: order.trackingNumber,
              shipDate: order.shipDate,
              id: order.id,
              businessCode: order.businessCode,
              businessName: order.businessName,
              settlementAmount: order.settlementAmount?.toLocaleString(),
              subsidy: order.subsidy?.toLocaleString(),
              settlementUnitPrice: order.settlementUnitPrice?.toLocaleString(),
              subsidyUnitPrice: order.subsidyUnitPrice?.toLocaleString(),
              supplyUnitPrice: order.supplyUnitPrice?.toLocaleString(),
              salesUnitPrice: order.salesUnitPrice?.toLocaleString(),
              orderMonth: order.orderMonth,
              regionName: order.regionName,
            }))
          }));
        } else {
          console.error("content 배열이 비어있거나 잘못된 데이터입니다:", response.data);
        }
      })
      .catch(error => console.error('Error fetching order data:', error))
      .finally(() => setIsLoading(false));
  }, []);

  // 검색 필드
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedColumn, setSelectedColumn] = useState('');
  const [filteredRows, setFilteredRows] = useState([]);

  const columnOptions = orderData.columns.map(column => ({
    text: column.label,
    value: column.field
  }));

  return (
    <div className="d-flex">
      <div>
        <Sidebar />
      </div>
      <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
        <Navbar onPasswordChangeClick={() => openModal(username)} />
        <div style={{ height: "100%" }}>
          <div style={{ padding: "20px 5%", height: "calc(100% - 64px)", overflowY: "scroll" }}>
            {isLoading ? (
              <Loading message="데이터 로딩 중.." />
            ) : (
              <div style={{ display: "grid", gridTemplateColumns: "repeat(1, minmax(200px, 1500px))" }}>
                <div className="mt-5 w-100">
                  <h3 className="font-weight-bold mb-4" style={{ fontWeight: "bold" }}>주문내역</h3>
                  <div className="search-container">
                    <div className="search-input">
                      <CDBSelect
                        className="search-select"
                        options={columnOptions}
                        selected="검색목록"
                        onChange={e => setSelectedColumn(e.target.value)}
                      />
                      <CDBInput
                        className="search-field"
                        placeholder="검색어 입력"
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                      />
                    </div>
                  </div>
                  <CDBCard>
                    <CDBCardBody>
                      <CDBDataTable
                        className="table-header-center table-data-right order"
                        bordered
                        hover
                        scrollY
                        maxHeight="48vh"
                        data={orderData}
                        sortable={false}
                        searching={false}
                        infoLabel={["데이터", "~", ", 전체 데이터", "개"]}
                        paginationLabel={["이전", "다음"]}
                      />
                    </CDBCardBody>
                  </CDBCard>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};